import * as React from "react"

import Layout from "../components/Layout/Layout"

const NotFoundPage = () => (
  <Layout>
    <div>Page not found</div>
  </Layout>
)

export default NotFoundPage
